import { IVariableUsage, VariableType } from '@/models/variable.model';
import { List } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IVariableUsagesListProps {
  variableUsages: IVariableUsage[];
}

export const VariableUsagesList: FC<IVariableUsagesListProps> = ({ variableUsages }) => {
  const { t } = useTranslation(['common']);

  const renderListItem = (item: IVariableUsage, index: number) => {
    if (item.type === VariableType.PAGE) {
      return (
        <List.Item key={index}>
          {index + 1}. {t('channel_name_usage')}: <strong>{item.channelName}</strong>, {t('page_name_usage')}:{' '}
          <strong>{item.pageName}</strong>, {t('usages')}: <strong>{item.usages}</strong>.
        </List.Item>
      );
    }

    return (
      <List.Item key={index}>
        {index + 1}. {t('channel_name_usage')}: <strong>{item.channelName}</strong>, <strong>{t('settings')}</strong>,{' '}
        {t('usages')}: <strong>{item.usages}</strong>.
      </List.Item>
    );
  };
  return (
    <List
      header={<strong>{t('variable_usages')}</strong>}
      bordered
      dataSource={[...variableUsages].sort((a, b) => a.channelName.localeCompare(b.channelName))}
      renderItem={renderListItem}
    />
  );
};
