// Globals
import { useTranslation } from 'react-i18next';

// Modules
import { getChannelsOptions } from '@/modules/Channels';

// Models
import { IChannel } from '@/models/channel.model';

// Components
import { FormItem, IFormInstance } from '@/components';
import { ChannelIcon, CopyrightIcon, HashIcon, LabelIcon, PhoneIcon, UserIcon } from '@/icons';
import { Checkbox, Input, Select, TextArea } from '@/ui';
import SettingBlock from '../SettingBlock/SettingBlock';
import { StyledForm, StyledLabel } from './GeneralSettingsFormStyles';

// Helpers
import { VariableDetector } from 'src/shared/ui/VariablesDetector';
import { useVariableDetector } from 'src/shared/ui/VariablesDetector/hooks/useVariableDetector';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import {
  GeneralSettingsBulkFormFields,
  GeneralSettingsFormFields,
  TGeneralSettingsBulkFormValues,
} from '../../helpers/types';
import { useGeneralSettingsBulkFormRules } from '../../hooks';

type IGeneralSettingsFormProps = {
  form: IFormInstance;
  channels: IChannel[];
  onFinish?: (values: Partial<TGeneralSettingsBulkFormValues>) => void;
  initialValues?: Partial<TGeneralSettingsBulkFormValues>;
};

const GeneralSettingsBulkForm = ({ form, onFinish, initialValues, channels }: IGeneralSettingsFormProps) => {
  const { t } = useTranslation(['common']);
  const rules = useGeneralSettingsBulkFormRules();
  const { variables, selectVariableInInput, registerInputRef, handleInputChange } = useVariableDetector();

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />

      <StyledForm form={form} onFinish={onFinish} initialValues={initialValues}>
        <SettingBlock title={t('title_channels')} icon={<ChannelIcon />}>
          <FormItem name={GeneralSettingsFormFields.CHANNEL} rules={rules[GeneralSettingsFormFields.CHANNEL]}>
            <Select options={getChannelsOptions(channels)} mode="multiple" />
          </FormItem>
        </SettingBlock>
        <SettingBlock
          title={t('title_google_tag_management')}
          icon={<LabelIcon />}
          additionalControls={
            <FormItem name={GeneralSettingsBulkFormFields.GOOGLE_TAG_MANAGER} valuePropName="checked">
              <Checkbox />
            </FormItem>
          }
        >
          <FormItem name={GeneralSettingsFormFields.GOOGLE_TAG_MANAGER}>
            <TextArea
              ref={(ref) => registerInputRef(t('title_google_tag_management'), ref, VariableType.TEXT_AREA)}
              onChange={(e) => handleInputChange(t('title_google_tag_management'), e.target.value)}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock
          title={t('title_admin_email')}
          icon={<UserIcon />}
          additionalControls={
            <FormItem name={GeneralSettingsBulkFormFields.ADMIN_EMAILS} valuePropName="checked">
              <Checkbox />
            </FormItem>
          }
        >
          <FormItem name={GeneralSettingsFormFields.ADMIN_EMAILS} rules={rules[GeneralSettingsFormFields.ADMIN_EMAILS]}>
            <Select
              ref={(ref) => registerInputRef(t('title_admin_email'), ref, VariableType.SELECT)}
              onChange={(value) => handleInputChange(t('title_admin_email'), (value as []).join(', '))}
              dropdownStyle={{ display: 'none' }}
              mode="tags"
              suffixIcon={null}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_smtp')} icon={<HashIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_PORT}
            rules={rules[GeneralSettingsFormFields.SMTP_PORT]}
            label={
              <StyledLabel>
                {t('label_smtp_port')}
                <FormItem name={GeneralSettingsBulkFormFields.SMTP_PORT} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_port'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_port'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_HOST}
            rules={rules[GeneralSettingsFormFields.SMTP_HOST]}
            label={
              <StyledLabel>
                {t('label_smtp_host')}
                <FormItem name={GeneralSettingsBulkFormFields.SMTP_HOST} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_host'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_host'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_AUTH_USER}
            rules={rules[GeneralSettingsFormFields.SMTP_AUTH_USER]}
            label={
              <StyledLabel>
                {t('label_smtp_username')}
                <FormItem name={GeneralSettingsBulkFormFields.SMTP_AUTH_USER} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_username'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_username'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.SMTP_AUTH_PASS}
            rules={rules[GeneralSettingsFormFields.SMTP_AUTH_PASS]}
            label={
              <StyledLabel>
                {t('label_smtp_password')}
                <FormItem name={GeneralSettingsBulkFormFields.SMTP_AUTH_PASS} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_smtp_password'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_smtp_password'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock
          title={t('title_copyright')}
          icon={<CopyrightIcon />}
          additionalControls={
            <FormItem name={GeneralSettingsBulkFormFields.COPYRIGHT} valuePropName="checked">
              <Checkbox />
            </FormItem>
          }
        >
          <FormItem name={GeneralSettingsFormFields.COPYRIGHT}>
            <Input
              ref={(ref) => registerInputRef(t('title_copyright'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('title_copyright'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock
          title={t('title_popular_products')}
          icon={<UserIcon />}
          additionalControls={
            <FormItem name={GeneralSettingsBulkFormFields.POPULAR_PRODUCTS} valuePropName="checked">
              <Checkbox />
            </FormItem>
          }
        >
          <FormItem name={GeneralSettingsFormFields.POPULAR_PRODUCTS}>
            <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} maxCount={8} />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_phones')} icon={<PhoneIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.PHONES_FOOTER}
            rules={rules[GeneralSettingsFormFields.PHONES_FOOTER]}
            label={
              <StyledLabel>
                {t('label_phones_footer')}
                <FormItem name={GeneralSettingsBulkFormFields.PHONES_FOOTER} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_phones_footer'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_phones_footer'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.PHONES_CONTACT_US}
            rules={rules[GeneralSettingsFormFields.PHONES_CONTACT_US]}
            label={
              <StyledLabel>
                {t('label_phones_contact')}
                <FormItem name={GeneralSettingsBulkFormFields.PHONES_CONTACT_US} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_phones_contact'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_phones_contact'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
        <SettingBlock title={t('title_contact_info')} icon={<PhoneIcon />}>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_ADDRESS}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]}
            label={
              <StyledLabel>
                {t('label_contact_info_address')}
                <FormItem name={GeneralSettingsBulkFormFields.CONTACT_INFO_ADDRESS} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <TextArea
              ref={(ref) => registerInputRef(t('label_contact_info_address'), ref, VariableType.TEXT_AREA)}
              onChange={(e) => handleInputChange(t('label_contact_info_address'), e.target.value)}
              rows={3}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_EMAIL}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_EMAIL]}
            label={
              <StyledLabel>
                {t('label_contact_info_email')}
                <FormItem name={GeneralSettingsBulkFormFields.CONTACT_INFO_EMAIL} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_contact_info_email'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_contact_info_email'), e.target.value)}
            />
          </FormItem>
          <FormItem
            name={GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS}
            rules={rules[GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]}
            label={
              <StyledLabel>
                {t('label_contact_info_working_hours')}
                <FormItem name={GeneralSettingsBulkFormFields.CONTACT_INFO_WORKING_HOURS} valuePropName="checked">
                  <Checkbox />
                </FormItem>
              </StyledLabel>
            }
          >
            <Input
              ref={(ref) => registerInputRef(t('label_contact_info_working_hours'), ref, VariableType.INPUT)}
              onChange={(e) => handleInputChange(t('label_contact_info_working_hours'), e.target.value)}
            />
          </FormItem>
        </SettingBlock>
      </StyledForm>
    </>
  );
};

export default GeneralSettingsBulkForm;
