import {
  useGetEmailRule,
  useGetNumbersOnlyRule,
  useGetPhoneRule,
  useGetRequiredRule,
  useLengthValidationRule,
} from '@/hooks';
import { useTranslation } from 'react-i18next';
import { GeneralSettingsFormFields } from '../helpers/types';

const useGeneralSettingsFormRules = () => {
  const { t } = useTranslation(['validations']);

  const phoneRule = useGetPhoneRule({ t });
  const lengthRule = useLengthValidationRule({ t, max: 100 });
  const emailRule = useGetEmailRule({ t });
  const numbersOnlyRule = useGetNumbersOnlyRule({ t });
  const requiredRule = useGetRequiredRule({ t });

  return {
    [GeneralSettingsFormFields.CHANNEL]: [requiredRule],
    [GeneralSettingsFormFields.PHONES_CONTACT_US]: [phoneRule],
    [GeneralSettingsFormFields.PHONES_FOOTER]: [phoneRule],
    [GeneralSettingsFormFields.SMTP_PORT]: [numbersOnlyRule],
    [GeneralSettingsFormFields.SMTP_AUTH_PASS]: [],
    [GeneralSettingsFormFields.SMTP_AUTH_USER]: [lengthRule],
    [GeneralSettingsFormFields.SMTP_HOST]: [lengthRule],
    [GeneralSettingsFormFields.CONTACT_INFO_EMAIL]: [emailRule, requiredRule],
    [GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]: [requiredRule],
    [GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]: [requiredRule],
    [GeneralSettingsFormFields.ADMIN_EMAILS]: [
      {
        validator: (_: unknown, value: string[]) => {
          if (value?.some((email) => !emailRule.pattern.test(email))) {
            return Promise.reject(new Error(emailRule.message));
          }

          return Promise.resolve();
        },
        message: emailRule.message,
      },
    ],
  };
};

export default useGeneralSettingsFormRules;
